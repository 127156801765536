@import 'functions';

/* General colors */
$primary: #633189;
$primary-bright: #753bbd;
$disabled-text: #776f6f;
$blue: #66b2df;
$red: #ff3b30;
$green: #4cd964;
$yellow: #fbc11e;
$orange: #f5a623;
$white: #fff;
$gray: #ddd;
$kind-gray: #f2f2f2;
$light-gray: #f0f0f0;
$card-subtitle: #4c4c4c;
$dark-gray: #333;
$mid-gray: #3a3a3a;
$body-color: #212529;
$body-background: #f9f9f9;
$danger: #e31d3a;
$success: #4cd964;
$text-semi-transparent: rgba(51, 51, 51, 0.5);
$text-partial-transparent: rgba(51, 51, 51, 0.8);
$form-layout-divider: #e4e4e4;
$uploader-border: #d0d0d0;
$danger-button-box-shadow: rgba(255, 59, 48, 0.25);
$primary-button-box-shadow: rgba(99, 49, 137, 0.5);
$outer-space: #424242;
$light-greyish-blue: #dee2e6;
$spinner-color: #808080;
$app-version-color: #4f5152;
$warning-popup: rgb(245, 166, 35);
$login-loader: rgba(152, 128, 255, 0);

/* borders */
$outer-border-light: #0000000f;

/* Fonts */
$font-light: 'Source Sans Pro' light;
$font-regular: 'Source Sans Pro' regular;
$font-semi-bold: 'Source Sans Pro' semibold;
$font-bold: 'Source Sans Pro' bold;

/* Spacing */
$sidebar-width: rem-calc(240);
$dashboard-page-padding: rem-calc(32);

/* Mobile Header */
$mobile-header-height: rem-calc(72);
$mobile-header-box-shadow: var(--mobile-nav-bar-shadow);
$mobile-header-background: $white;
$mobile-nav-bar-border-color: $gray;

/* Mobile subheader tab-menu */
$subheader-menu-shadow-box: 0 5px 13px 0 rgba(0, 0, 0, 0.15);

/* Harvest App */
$mobile-horizontal-gutters: 25px;

/* Other */
$card-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

/* Masonry layout */
$gutter-size: 15px;

// Time picker
$timepicker-color: #a6a6a6;

/* Heights (z-index) */
$z-index-backdrop-overlay: 900;
$z-index-above-backdrop-overlay: 901;

/* Styles for editable table */
$delimiter: var(--et-delimiter-color);
$header-color: var(--et-header-color);
$row-background: var(--et-row-background);
$row-padding: var(--et-row-padding);
$row-border-color: var(--et-row-border-color);
$gutter-border-size: var(--et-gutter-border-size);
$gutter-border-color: var(--et-gutter-border-color);
