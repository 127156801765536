@import 'variables';

@mixin commonTableCellStyles() {
  border: none;
  border-left: 1px solid $gray;
  height: 50px;
  text-align: center;
  font-weight: 600;
  vertical-align: top;
}

@mixin setRightGutters($value) {
  & > * {
    margin-right: $value;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin blocktify() {
  display: block;
  width: 100%;
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setTopGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-top: $value;

    &:last-child {
      margin-top: $lastGutter;
    }
  }
}

@mixin setBottomMargin($value) {
  margin-bottom: $value;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin proportions($width, $height) {
  width: $width;
  height: $height;
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin setAbsoluteCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  left: $size;
  transform: translate(-$size, -$size);
}

@mixin setAbsoluteVerticalCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  transform: translateY(-$size);
}

@mixin setAbsoluteHorizontalCenter() {
  $size: 50%;
  position: absolute;
  left: $size;
  transform: translateX(-$size);
}

@mixin setFontAwesomeFreeIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Free';
  font-weight: $weight;
  content: $code;
}

@mixin setFontAwesomeProIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Pro';
  font-weight: $weight;
  content: $code;
}

@mixin straightRight($radius: 0) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin straightLeft($radius: 0) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin straightTop($radius: 0) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin straightBottom($radius: 0) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin sectionDivider($vertical-margins, $color) {
  margin: $vertical-margins 0;
  padding: $vertical-margins 0;
  border-bottom: 1px solid $color;

  &:last-of-type {
    border: none;
    margin: 0;
  }
}

@mixin sectionDividerRight($color) {
  border-right: 1px solid $color;

  &:last-of-type {
    border: none;
    margin: 0;
  }
}

@mixin card-layout($padding) {
  padding: $padding;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 2px 4px 1px rgba(202, 202, 202, 0.5);
}

@mixin setColsGutters($gutters) {
  & > * {
    padding: $gutters;
  }
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexCenteringWithSpaceBetween() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexStartWithSpaceBetween() {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin truncateAndDots($maxWidth) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin filledStyles() {
  font-weight: 600;
  color: $dark-gray;
}

@mixin setPlaceholderStyles($color: #666, $font-weight: normal, $font-size: 14px) {
  /* stylelint-disable */
  &:-ms-input-placeholder {
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
  }

  &::-webkit-input-placeholder {
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
  }

  &::placeholder {
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
  }
}

@mixin inputNumberHideArrows() {
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

@mixin tabFocus() {
  border: 1px solid $primary !important;
  box-shadow: 0 2px 4px 0 $primary-button-box-shadow;
}

@mixin responsiveDialog() {
  .p-dialog-header {
    z-index: 1;
  }

  .p-dialog-content {
    max-height: calc(100% - 50px) !important;
    width: 100%;
    min-width: auto;
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      min-width: 40vw;
    }
  }
}

@mixin lg-40() {
  @include media-breakpoint-up(xl) {
    width: 40% !important;
  }
}

@mixin lg-50() {
  @include media-breakpoint-up(lg) {
    width: 50% !important;
  }
}

@mixin lg-60() {
  @include media-breakpoint-up(lg) {
    width: 60% !important;
  }
}

@mixin lg-70() {
  @include media-breakpoint-up(lg) {
    width: 70% !important;
  }
}

@mixin gray-body() {
  .p-dialog-content {
    background-color: $body-background !important;
  }
}

@mixin dialog-padding-0() {
  .p-dialog-content {
    padding: 0 !important;
  }
}

@mixin apply-column-widths($columns) {
  @each $index, $width in $columns {
    th:nth-child(#{$index}),
    td:nth-child(#{$index}) {
      width: $width;

      @if $width != 0 {
        div {
          text-overflow: initial;
          white-space: normal;
        }
      }
    }
  }
}

@mixin right-total-column() {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
/* stylelint-enable */
