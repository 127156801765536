.list-renderer-widget-image {
  position: absolute;
  transform: translate(0%, -50%);

  &--square {
    border-radius: 4px;

    > img {
      border-radius: 4px;
    }
  }

  &--circle {
    border-radius: 50%;

    > img {
      border-radius: 50%;
    }
  }

  .thumbnail {
    width: 45px;
    height: 45px;
  }

  .enlarged-thumbnail {
    display: none;
    opacity: 0;
  }
}

.list-renderer-widget-image:hover {
  .enlarged-thumbnail {
    display: block;
    width: 210px;
    height: 135px;
    opacity: 1;
    transition: opacity 0.3s ease;
    transform: translate(0, -47%);
  }
}
