@import 'abstracts';

app-list-renderer-widget-input-number-with-buttons {
  cap-input-number .p-inputgroup .p-button {
    height: 100%;
    max-height: 38px;
    width: 38px;
    padding: 0;

    &.minus {
      @include straightRight();
      border-right: 0;
    }

    &.plus {
      @include straightLeft();
      border-left: 0;
    }

    span.p-button-icon {
      color: $primary !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .cap-button.secondary:not(:disabled),
  .cap-button.secondary:enabled:hover {
    border: 1px solid $gray;
  }
}
