@import 'abstracts';

app-reorder-lots {
  .reorder-buttons {
    @include flexCenteringWithSpaceBetween;
    padding-top: 15px;

    cap-button p-button {
      button {
        span.p-button-icon {
          font-size: 1rem !important; /* stylelint-disable-line declaration-no-important */
          font-weight: bold;
          color: $primary !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      .p-button.cap-button {
        height: 36px;
        width: 38px;
        margin-bottom: 15px;
        padding-left: 24px;
      }
    }
  }
}
